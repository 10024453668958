* {
    -webkit-tap-highlight-color: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

*:active, *:focus {
    outline: none
}

input::-moz-focus-inner, select::-moz-focus-inner, option::-moz-focus-inner, textarea::-moz-focus-inner {
    border: 0;
    outline: none
}

img {
    max-width: 100%;
    height: auto;
}

picture {
    max-width: 100%;
    height: auto;
}

html, body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #1F1F1F;
    font-family: $font-main;
    scroll-behavior: smooth;
}

body {
    position: relative;
    text-transform: none;
    background-color: #F3F3F3;
    // padding-top: 194px;
    transition: .3s padding-top ease-in-out;

    &.sticky-header {
        // padding-top: 108px;
    }
}

ul, ol {
    padding: 0;
    margin: 0;
    list-style: none
}

a {
    color: inherit;
    text-decoration: none;
    outline: none
}