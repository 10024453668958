.popular-courses {
    background-color: #fff;
    padding: 17px 20px;
    margin-bottom: 30px;
    &__item {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #F3F3F3;
        &:last-of-type {
            margin-bottom: 0px;
            border-bottom: none;
        }
        &-image {
            max-width: 70px;
            flex: 0 0 70px;
            margin-right: 20px;
            img {
                border-radius: 50%;
            }
        }
        &-title {
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
        }
    }
}