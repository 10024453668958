.loading {
    .loader {
        opacity: 1;
        pointer-events: all;
    }
}

.loader {
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;

    &__area {
        display: inline-block;
        position: relative;
        width: 78px;
        height: 13px;

        div {
            position: absolute;
            top: 0;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: #CD1719;
            animation-timing-function: linear;

            &:nth-child(1) {
                left: 8px;
                animation: loader-element1 0.6s infinite;
            }

            &:nth-child(2) {
                left: 8px;
                animation: loader-element2 0.6s infinite;
            }

            &:nth-child(3) {
                left: 32px;
                animation: loader-element2 0.6s infinite;
            }

            &:nth-child(4) {
                left: 56px;
                animation: loader-element4 0.6s infinite;
            }
        }
    }
}

@keyframes loader-element1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes loader-element4 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes loader-element2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}