.magazines {
    margin-top: -30px;
    padding-top: 30px;
    position: relative;
    .subpage-title {
        z-index: 1;
        position: relative;
    }
    &__top {
        margin-bottom: 52px;
    }
    &::after {
        content: '';
        height: 481px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        background: linear-gradient(45deg, rgba(31,31,31,1) 0%,rgba(80,10,10,1) 100%);
        @media(max-width: 1024px) {
            height: 350px;
        }
    }
    &__item {
        position: relative;
        z-index: 1;
        text-align: center;
        margin-bottom: 40px;
        a {
            display: block;
        }
        &-image {
            margin-bottom: 30px;
            img {
                box-shadow: 0 20px 99px rgba(0,0,0,.4);
            }
        }
        &-title {
            font-size: 23px;
            line-height: 30px;
            letter-spacing: -0.025em;
            margin-bottom: 10px;
            font-weight: 700;
        }
        &-description {
            font-size: 17px;
            line-height: 20px;
            margin-bottom: 17px;
            color: #898989;
        }
        &-btn {
            background-color: #CD1719;
            padding: 20px 30px;
            border-radius: 142px;
            display: inline-block;
            max-width: 190px;
            margin: 0 auto;
            color: #fff;
            text-transform: uppercase;
            font-size: 15px;
            line-height: 18px;
            font-weight: 500;
            transition: .3s all ease-in-out;
            &:hover {
                background-color: #F62023;
            }
        }
    }
    &__description {
        padding: 80px 0;
        background-color: #fff;
        @media(max-width: 600px) {
            padding: 30px 0;
        }
        &-wrapper {
            display: flex;
            align-items: center;
            @media(max-width: 600px) {
                flex-wrap: wrap;
            }
            &-image {
                max-width: 170px;
                flex: 0 0 170px;
                margin-right: 46px;
                @media(max-width: 920px) {
                    margin-right: 15px;
                }
                @media(max-width: 600px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    text-align: center;
                    margin-bottom: 20px;
                    margin-right: 0px;
                }
            }
            &-text {
                font-size: 18px;
                line-height: 25px;
                color: #1F1F1F;
                @media(max-width: 600px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    text-align: center;
                }
            }
        }
    }
}