footer {
    position: relative;
    padding: 60px 0 10px;
    background: #303136;
    line-height: 1;
    z-index: 5;

    .title {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
    }

    // Fix class collision (remove Bootstrap4 dependencies)
    .force-float {
        .row {
            display: block;
            flex-wrap: unset;
        }

        .col-1,
        .col-2,
        .col-3 {
            flex: none;
            max-width: none;
        }
    }

    .nav {
        display: unset;
        flex-wrap: unset;
    }

    .pull-left  { float: left !important; }
    .pull-right { float: right !important; }

    // Fix class collision - End

    .mini-title {
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 30px;
        color: #fff;
        cursor: pointer;
        display: block; // Changed from inline-block due to float property
        float: left;
        clear: both;
    }

    ul {
        display: block;
        list-style: none;
        margin-top: 30px;
        margin-bottom: 20px;
        padding: 0;
        overflow: hidden;
        &.hide-list {
            display: none;
        }

        li {
            + li {
                margin-top: 10px;
            }

            a {
                display: inline;
                padding: 0;
                color: #fff !important;
                font-size: 14px;

                transition: color .25s ease;

                &:hover, &:focus {
                    color: #d4d4d5;
                    text-decoration: none;
                    background-color: transparent;
                }
            }
            &.page-395 {
                display: none;
            }
        }
    }

    .ul-list ul {
        padding-left: 15px;
        padding-right: 15px;

        &.col-2, &.col-3 {
            margin-top: 10px;
        }

        li {
            span {
                display: block;
                margin: 30px 0;
                font-size: 14px;
                text-transform: uppercase;
                color: #ffffff;
            }

            &:first-child {
                span {
                    margin-top: 0;
                }
            }
        }
    }

    .footer-line {
        border-top: 3px solid #494a4e;
        padding: 40px 0 40px 0;
        margin-top: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width: 500px) {
            flex-wrap: wrap;
        }
    }

    .col-xs-12.pull-left {
        margin-top: 30px;
    }

    .social {
        a {
            display: block;
            float: left;
            width: 26px;
            height: 26px;
            margin: 0 0 0 6px;
            border: 1px solid #ababab;
            color: #fff;
            border-radius: 2px;
            text-align: center;
            font-size: 16px;
            overflow: hidden;
            padding: 4px;

            i { color: inherit; }

            &:hover, &:focus {
                .fab {
                    color: #d4d4d5;
                    transform: rotate(360deg);
                }
            }

            .fab {
                color: #ababab;
                line-height: 24px;
            }
        }
    }

    .footer-logo {
        display: block;
        width: 98px;
        height: 36px;
        background: url("/front/images/sprite.png") -32px 0 no-repeat;
        float: none;
        @media(max-width: 500px) {
            margin: 0 auto 30px auto;
        }
    }
    // $breakpoint-extra-mobile-max:     575.98px;
    // $breakpoint-mobile-min:           576px;
    // $breakpoint-mobile-max:           767.98px;
    // $breakpoint-tablet-min:           768px;
    // $breakpoint-tablet-max:           1024.98px;
    // $breakpoint-desktop-min:          1025px;
    // $breakpoint-desktop-max:          1199.98px;
    // $breakpoint-desktop-large-min:    1200px;
    // $breakpoint-desktop-large-max:    1279.98px;
    // $breakpoint-desktop-xxl-min:      1280px;
    /* Tablet-up */
    @media(min-width:768px) {
        padding: 60px 0 10px;

        .ul-list ul {
            width: 33.33333333%;
            float: left;

            &.col-2, &.col-3 {
                margin-top: 30px;
            }
        }

        .footer-line {
            padding: 53px 0 53px 0;
        }

        .col-xs-12.pull-left {
            margin-top: 0;
        }


    }

    /* Desktop-up */
    @media(min-width:1200px) {

        padding: 72px 0 10px;

        .footer-line {
            margin-top: 105px;
        }
    }
}

// Our Sites
.our-sites {
    margin-top: 30px;

    .page-group { margin-bottom: 30px; }

    .group-title {
        margin-bottom: 30px;
        color: $color-white;
        font-size: 14px;
        line-height: 1.4;
        text-transform: uppercase;
    }

    .group-list {
        padding: 0;
        list-style: none;
        list-style-type: none;
    }

    .group-item {
        padding-top: 5px;
        padding-bottom: 5px;

        &:first-child { padding-top: 0px; }
    }

    .group-link {
        color: #fff;
        font-size: 14px;

        &:hover { color: lighten(#ababab, 15%); }
    }

}
#shortcuts-menu {
    margin: 0 auto;
    li {
        line-height: 27px;
        a {
            color: #fff;
        }
    }
}
.publisher {
    color: #fff;
    img {
        max-width: 143px;
    }
}
.shortcuts {
    img {
        margin-top: 37px;
        margin-bottom: 39px;
    }
}


.footer-line {
    &__right {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #6B6969;
        @media(max-width: 500px) {
            max-width: 100%;
            flex: 0 0 100%;
            justify-content: center;
        }
        &-shop {
            margin-right: 30px;
        }
        &-copyrights {
            a {
                color: #AAAAAA;
            }
        }
    }
}