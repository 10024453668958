.firm-list {
    &__header {
        font-size: 30px;
        line-height: 43px;
        margin: 0 0 30px;
        text-transform: uppercase;
        font-weight: 400;
        @media (min-width: 768px) {
            font-size: 35px;
            line-height: 48px;
        }
        @media(min-width: 1025px) {
            font-size: 40px;
            line-height: 53px;
        }
    }

    &__items {
        max-width: 772px;
        margin: 0 auto 50px;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #DCDCDC;
        @media(min-width: 576px) {
            flex-wrap: nowrap;
        }

        &:hover {
            color: #CD1719;
        }

        &-title {
            flex-grow: 2;
            font-size: 16px;
            line-height: 22px;
            font-weight: 700;
            transition: color .2s ease-in-out;
            @media(min-width: 576px) {
                padding-right: 30px;
            }
        }

        &-link {
            margin-left: auto;
            margin-right: 0;
            color: #CD1719;
            font-size: 12px;
            font-weight: 700;
            white-space: nowrap;
            text-transform: uppercase;
            width: 100%;
            margin-top: 10px;
            text-align: left;
            @media(min-width: 576px) {
                text-align: right;
                width: auto;
                margin-top: 0;
            }
        }
    }
}