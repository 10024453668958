.on-top {
    margin-bottom: 50px;
    margin: 0 auto;
    display: none;
    @media(min-width: 1025px) {
        display: block;
    }
    &.mobile {
        display: block;
        @media(min-width: 1025px) {
            display: none;
        }
    }
    &__item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(31,31,31,.1);
        position: relative;
        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            max-width: 0%;
            width: 100%;
            height: 1px;
            background-color: #CD1719;
            transition: .5s all ease-in-out;
            opacity: 0;
        }
        &:hover {
            .on-top__item-text-title {
                color: #CD1719;
            }
            .on-top__item-image {
                img {
                    transform: scale(1.5);
                }
            }
            &::after {
                max-width: 100%;
                opacity: 1;
            }
        }
        &-text {
            margin-right: 20px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            max-width: calc(100% - 150px);
            flex: 0 0 calc(100% - 150px);
            &-title {
                font-size: 17px;
                line-height: 19px;
                font-weight: 700;
                margin-bottom: 10px;
                transition: .5s all ease-in-out;
                max-width: 100%;
                width: 100%;
            }
            &-info {
                display: flex;
                align-items: center;
                &-type {
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 600;
                    letter-spacing: 0.020em;
                    margin-right: 9px;
                    text-transform: uppercase;
                }
            }
        }
        &-image {
            max-width: 130px;
            flex: 0 0 130px;
            overflow: hidden;
            display: block;
            max-width: 130px;
            flex: 0 0 130px;
            img {
                display: block;
                transition: .3s all ease-in-out;
            }
        }
    }
}