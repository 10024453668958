.search-wrapper {
    &__form {
        margin-bottom: 18px;
        position: relative;
        &-input {
            width: 100%;
            height: 107px;
            input {
                width: 100%;
                height: 100%;
                font-size: 25px;
                line-height: 19px;
                font-weight: 700;
                font-family: 'Public Sans';
                padding: 26px;
                border: 2px solid #DCDCDC;
            }
        }
        &-icons {
            position: absolute;
            right: 26px;
            top: 50%;
            transform: translate(0,-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            &-submit {
                cursor: pointer;
                margin-right: 21px;
                background-color: transparent;
                border: none;
            }
            &-close {
                cursor: pointer;
            }
        }
    }
}
.search-results {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 24px;
    .bold {
        font-weight: 700;
    }
}

.search-no-results {
    margin-bottom: 100px;
    &__image {

    }
    &__text {
        font-size: 43px;
        line-height: 52px;
        font-weight: 700;
        text-align: center;
        margin-top: -22px;
    }
}