.horizontal-article {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(31,31,31,.1);
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        max-width: 0%;
        width: 100%;
        height: 1px;
        background-color: #CD1719;
        transition: .5s all ease-in-out;
        opacity: 0;
    }
    &:hover {
        .horizontal-article__image {
            img {
                transform: scale(1.5);
            }
        }
        .horizontal-article__text-title {
            color: #CD1719;
        }
        &::after {
            max-width: 100%;
            opacity: 1;
        }
    }
    &__image {
        margin-bottom: 30px;
        display: block;
        overflow: hidden;
        @media(min-width: 768px) {
            margin-bottom: 0px;
        }
        img {
            display: block;
            transition: .5s all ease-in-out;
        }
    }
    &__text {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        height: 100%;
        &-top {
            max-width: 100%;
            width: 100%;
        }
        &-title {
            font-size: 20px;
            line-height: 26px;
            font-weight: 700;
            letter-spacing: -0.025em;
            margin-bottom: 12px;
            display: block;
            transition: .5s all ease-in-out;
        }
        &-introtext {
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 17px;
            display: block;
            @media(max-width: 550px) {
                display: none;
            }
        }
        &-info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            &-type {
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.020em;
                font-weight: 600;
                margin-right: 15px;
                text-transform: uppercase;
            }
            &-category {
                margin-right: 23px;
                &-item {
                    background-color: #E8EBEE;
                    padding: 4px 5px;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.020em;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-right: 2px;
                    display: inline-block;
                    &.white {
                        background-color: #fff;
                    }
                }
            }
            &-date, &-views {
                font-size: 13px;
                line-height: 24px;
                display: flex;
                align-items: center;
                margin-right: 22px;
                @media(max-width: 550px) {
                    display: none;
                }
                img {
                    margin-right: 10px;
                }
            }
        }
    }
}