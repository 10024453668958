.quiz-item {
    background-color: $color-white;
    display: block;
    padding: 20px 15px 15px;
    text-align: center;
    margin: 0 0 30px;
    &__image {
        margin-bottom: 20px;
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
    &__title {
        font-size: 19px;
        font-weight: 700;
        margin: 0 0 25px;
    }
    &__views {
        font-size: 13px;
        font-weight: 400;
        color: #898989;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-right: 10px;
        }
    }
}
