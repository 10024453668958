.single-author {
    &__info {
        background-color: #fff;
        box-shadow: 0 10px 50px rgba(0,0,0,.16);
        padding: 30px 24px 30px 30px;
        display: flex;
        margin-bottom: 30px;
        &-image {
            width: 109px;
            height: 109px;
            border-radius: 50%;
            max-width: 109px;
            margin-right: 30px;
            img {
                border-radius: 50%;
            }
        }
        &-text {
            max-width: calc(100% - 139px);
            flex: 0 0 calc(100% - 139px);
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            &.w-100 {
                max-width: 100%;
                flex: 0 0 100%;
            }
            &-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 100%;
                flex: 0 0 100%;
                &-name {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 700;
                }
            }
            &-description {
                font-size: 15px;
                line-height: 24px;
            }
        }
    }
    &__filters {
        background-color: #fff;
        padding: 20px 30px 30px 30px;
        margin-bottom: 15px;
        &-title {
            font-size: 18px;
            letter-spacing: 0.075em;
            font-weight: 300;
            text-transform: uppercase;
            padding-bottom: 10px;
            border-bottom: 2px solid #CD1719;
            margin-bottom: 20px;
        }
        &-items {
            &-item {
                display: block;
                font-size: 16px;
                line-height: 32px;
                font-weight: 700;
                &.active {
                    color: #CD1719;
                }
            }
        }
    }
}