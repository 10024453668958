.more-from-category {
    &__category {
        font-size: 11px;
        letter-spacing: 0.075em;
        color: #CD1719;
        text-transform: uppercase;
        font-weight: 500;
    }
    &__top {
        display: flex;
        justify-content: space-between;
    }
    &__items {
        &-item {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid rgba(31,31,31,.1);
            &-image {
                margin-bottom: 20px;
                @media(min-width: 768px) {
                    margin-bottom: 0px;
                }
            }
            &-text {
                &-title {
                    font-size: 21px;
                    line-height: 26px;
                    font-weight: 700;
                    margin-bottom: 25px;
                }
                &-introtext {
                    font-size: 15px;
                    line-height: 24px;
                }
            }
        }
    }
}