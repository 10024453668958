.box-article-mid {
    background-color: #fff;
    margin-bottom: 30px;
    text-align: center;
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    &__top {
        max-width: 100%;
        flex: 0 0 100%;
    }
    &.h-100 {
        height: 100%;
    }
    &--horizontal { 
        margin-bottom: 40px;
        @media(min-width: 768px) {
            text-align: left;
            display: flex;
            padding: 20px;
            box-shadow: 0 10px 50px rgba(0,0,0,.16);
        }
        .box-article-mid__title {
            @media(min-width: 768px) {
                padding: 0px;
            }
        }
        .box-article-mid__image {
            @media(min-width: 768px) {
                max-width: 504px;
                width: 100%;
                margin-right: 60px;
                margin-bottom: 0px;
            }
        }
        .box-article-mid__btn {
            @media(min-width: 768px) {
                justify-content: flex-start;
                &-wrapper {
                    max-width: 304px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .box-article-mid__description {
            @media(min-width: 768px) {
                margin-left: 0px;
            }
        }
    }
    &__recommend {
        font-size: 17px;
        line-height: 21px;
        font-weight: 700;
        letter-spacing: 0.020em;
        margin-bottom: 15px;
        text-transform: uppercase;
        color: #CD1719;
        display: block;
    }
    &__image {
        margin-bottom: 22px;
        display: block;
        img {
            display: block;
        }
    }
    &__title {
        font-size: 23px;
        line-height: 28px;
        letter-spacing: -0.025em;
        font-weight: 700;
        margin-bottom: 15px;
        padding: 0 15px;
        display: block;
    }
    &__partner {
        font-size: 15px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 15px;
        .red {
            color: #CD1719;
        }
    }
    &__description {
        font-size: 15px;
        line-height: 24px;
        max-width: 510px;
        margin: 0 auto 20px auto;
        display: block;
    }
    &__btn {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        &-wrapper {
            display: inline;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.050em;
            font-weight: 500;
            padding: 20px 30px;
            border-radius: 142px;
            text-transform: uppercase;
            &.active {
                background-color: #CD1719;
                border: 1px solid #CD1719;
                color: #fff;
            }
            &.inactive {
                background-color: #fff;
                border: 1px solid #707070;
                color: #898989;
            }
        }
    }
    &__text {
        &-space-between {
            display: flex;
            align-content: space-between;
            flex-wrap: wrap;
            &-top {
                max-width: 100%;
                flex: 0 0 100%;
            }
            &-bottom {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }
}