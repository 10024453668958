.box-article {
    background-color: #fff;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 30px;
    &.h-100 {
        height: 100%;
    }
    &__image {
        margin-bottom: 30px;
        display: block;
    }
    &__title {
        font-size: 23px;
        line-height: 28px;
        font-weight: 700;
        letter-spacing: -0.025em;
        max-width: 570px;
        margin: 0 auto 20px auto;
        display: block;
        padding: 0 30px;
    }
    &__introtext {
        font-size: 15px;
        line-height: 24px;
        max-width: 570px;
        text-align: center;
        margin: 0 auto 20px auto;
        display: block;
        padding: 0 30px;
    }
    &__info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        &-type {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.020em;
            font-weight: 500;
            color: #fff;
            padding: 4px 5px;
            background-color: #393939;
            margin-right: 10px;
            text-transform: uppercase;
        }
        &-category {
            margin-right: 23px;
            &-item {
                background-color: #E8EBEE;
                padding: 4px 5px;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.020em;
                font-weight: 600;
                text-transform: uppercase;
                margin-right: 2px;
                display: inline-block;
            }
        }
        &-date, &-views {
            font-size: 13px;
            line-height: 24px;
            display: flex;
            align-items: center;
            margin-right: 22px;
            img {
                margin-right: 10px;
            }
        }
    }
}