.rate-number {
    background-color: #003861;
    color: #ffffff;
    max-height: 110px;
    overflow: hidden;
    &__left {
        max-width: 110px;
        display: block;
        padding-top: 30px;
        @media(min-width: 576px) {
            max-width: none;
            padding-top: 35px;
        }
        @media(min-width: 768px) {
            padding-top: 18px;
        }
    }
    &__right {
        padding-top: 45px;
        display: block;
    }
    &__paper {
        display: block;
        height: auto;
        max-height: 110px;
        width: auto;
        @media(min-width: 480px) {
            max-width: 170px;
            max-height: none;
        }
        @media(min-width: 768px) {
            max-width: 200px;
        }
        @media(min-width: 1025px) {
            max-width: 100%;
        }
    }
    &__title {
        font-weight: 400;
        margin-bottom: 4px;
        font-size: 14px;
        @media(min-width: 768px) {
            font-size: 16px;
        }
        @media(min-width: 1025px) {
            font-size: 20px;
        }
        @media(min-width: 1200px) {
            font-size: 23px;
        }
        span {
            font-weight: 700;
            display: block;
            @media(min-width: 768px) {
                display: inline;
            }
        }
    }
    &__subtitle {
        font-weight: 400;
        margin-bottom: 4px;
        font-size: 12px;
        display: none;
        @media(min-width: 768px) {
            display: block;
        }
        @media(min-width: 1025px) {
            font-size: 16px;
        }
        @media(min-width: 1200px) {
            font-size: 18px;
        }
    }
    &__description {
        font-size: 12px;
        font-weight: 400;
        opacity: 0.5;
        display: none;
        @media(min-width: 768px) {
            display: block;
        }
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__link {
        font-weight: 700;
        padding-bottom: 5px;
        border-bottom: 2px solid #FFD300;
        text-transform: uppercase;
        font-size: 12px;
        @media(min-width: 576px) {
            font-size: 15px;
        }
        .sm {
            @media(max-width: 575px) {
                display: none;
            }
        }
        .lg {
            @media(max-width: 1024px) {
                display: none;
            }
        }
    }
}
