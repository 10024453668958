.magazine {
    &-article {
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
        display: none;
        @media(min-width: 1025px) {
            display: block;
        }
        &.white {
            color: #010101;
            background-color: #fff;
            .magazine-article__wrapper-title {
                color: #010101;
                margin-bottom: 10px;
            }
            .magazine-article__wrapper-date {
                color: #010101;
                font-size: 15px;
                line-height: 18px;
                margin-bottom: 10px;
            }
        }
        &__wrapper {
            display: block;
            z-index: 1;
            position: relative;
            padding: 25px 25px 44px 25px;
            text-align: center;
            &-description {
                font-size: 13px;
                line-height: 14px;
                opacity: 0.66;
                max-width: 246px;
                display: block;
                margin: 0 auto 15px auto;
            }
            &-title {
                font-size: 20px;
                line-height: 36px;
                letter-spacing: -0.025em;
                font-weight: 700;
                color: #fff;
                margin-bottom: 14px;
            }
            &-date {
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.003em;
                color: rgba(255,255,255,.5);
                margin-bottom: 20px;
            }
            &-image {
                margin-bottom: 36px;
                img {
                    box-shadow: 0 20px 30px rgba(0,0,0,.55);
                }
            }
            &-btn {
                background-color: #CD1719;
                padding: 20px 30px;
                border-radius: 142px;
                display: block;
                max-width: 190px;
                margin: 0 auto;
                color: #fff;
                text-transform: uppercase;
                font-size: 15px;
                line-height: 18px;
                font-weight: 500;
            }
        }
    }
}