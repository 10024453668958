.more-with-tag {
    &__category {
        font-size: 11px;
        color: #CD1719;
        letter-spacing: 0.075em;
        font-weight: 500;
        text-align: right;
        width: 100%;
        display: block;
    }
    &__item {
        display: block;
        margin-bottom: 50px;
        &-image {
            margin-bottom: 27px;
        }
        &-title {
            font-size: 21px;
            line-height: 26px;
            font-weight: 700;
            margin-bottom: 15px;
        }
        &-description {
            font-size: 15px;
            line-height: 24px;
        }
    }
}