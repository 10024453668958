.subscription {
    &-media {
        margin: 0 0 30px;
        img {
            margin: 0 auto;
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
    &-login {
        margin-top: 35px;
        &__top {
            margin-bottom: 120px;
        }
        &__form {
            background-color: #ffffff;
            flex-grow: 2;
            padding: 30px 15px;
            @media(min-width: 768px) {
                padding: 30px;
                max-width: calc(100% - 270px);
            }
            &-error {
                color: #CD1719;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
            }
            &-image {
                display: none;
                @media(min-width: 768px) {
                    display: block;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                }
            }
            &-title {
                font-size: 16px;
                font-weight: 700;
                padding-bottom: 20px;
                border-bottom: 1px solid #E2E2E2;
                width: 100%;
                margin-bottom: 30px;
            }
            &-description {
                text-align: center;
                max-width: 400px;
                margin: 0 auto;
                width: 100%;
                font-size: 15px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 30px;
                a {
                    color: #CD1719;
                    text-decoration: underline;
                    font-weight: 400;
                }
            }
            &-wrap {
                display: flex;
                flex-wrap: wrap;
                box-shadow: 0 10px 99px rgba(0,0,0,0.16);
                max-width: 770px;
                margin: 0 auto 30px;
                @media(min-width: 1200px) {
                    margin: 0;
                }
            }
            &-input {
                width: 100%;
                min-height: 70px;
                border-radius: 231px;
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.2em;
                border: 2px solid #E2E2E2;
                outline: none;
                box-shadow: none;
                margin-bottom: 25px;
            }
            &-submit {
                min-height: 70px;
                background-color: #F62023;
                color: #ffffff;
                width: 100%;
                border-radius: 231px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                border: none;
                outline: none;
                box-shadow: 0 20px 50px rgba(0,0,0,0.16);
                margin-bottom: 10px;
            }
        }
        &__uk {
            background-color: rgba(255,255,255,0.5);
            height: 100%;
            max-width: 770px;
            padding: 30px 15px;
            margin: 0 auto 30px;
            @media(min-width: 768px) {
                padding: 30px;
                padding-bottom: 15px;
            }
            @media(min-width: 1200px) {
                margin: 0;
                padding-bottom: 15px;
            }
            &-title {
                font-size: 16px;
                font-weight: 700;
                padding-bottom: 20px;
                border-bottom: 1px solid #E2E2E2;
                width: 100%;
                margin-bottom: 30px;
            }
            &-description {
                ul {
                    list-style-type: disc;
                    list-style-position: outside;
                    margin-left: 16px;
                }
            }
            &-subtitle, &-description {
                text-align: left;
                margin: 0 auto 20px;
                width: 100%;
                font-size: 15px;
                line-height: 24px;
                font-weight: 400;
            }
            &-btn {
                max-width: 225px;
                width: 100%;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                border: 2px solid #F62023;
                min-height: 70px;
                border-radius: 231px;
            }
            &-codeinfo {
                margin-top: 15px;
                text-align: center;
                font-size: 12px;
                color: #C5C5C5;
            }
        }
        &__info {
            margin-bottom: 150px;
            &-title {
                font-size: 30px;
                font-weight: 400;
                margin-bottom: 35px;
            }
            &-item {
                margin-bottom: 30px;
                background-color: #ffffff;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                min-height: 200px;
                padding: 15px;
                @media(min-width: 768px) {
                    padding: 15px 30px;
                    min-height: 250px;
                }
                @media(min-width: 1025px) {
                    padding: 15px 80px;
                }
                @media(min-width: 1200px) {
                    padding: 15px 110px;
                    min-height: 320px;

                }
                &-text {
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 28px;
                    margin-left: auto;
                    max-width: calc(100% - 60px);
                    @media(min-width: 576px) {
                        max-width: calc(100% - 95px);
                    }
                    @media(min-width: 768px) {
                        max-width: 400px;
                    }
                    @media(min-width: 1025px) {
                        max-width: 450px;
                        font-size: 18px;
                        line-height: 30px;
                        margin-left: 0;
                    }
                }
                &-step {
                    font-size: 67px;
                    font-weight: 700;
                    width: 45px;
                    margin-right: 15px;
                    @media(min-width: 576px) {
                        margin-right: 50px;
                    }
                    @media(min-width: 1025px) {
                        margin-right: 80px;
                    }
                    @media(min-width: 1200px) {
                        margin-right: 110px;
                    }
                }
                &-image {
                    margin-left: auto;
                    margin-right: 0;
                    justify-self: flex-end;
                    max-width: 150px;
                    display: none;
                    @media(min-width: 768px) {
                        display: block;
                    }
                    @media(min-width: 1025px) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
