.breadcrumb {
    z-index: 2;
    position: relative;
    margin-bottom: 20px;
    &.white {
        color: #fff;
    }
    &__list {
        &-item {
            font-size: 13px;
            &:last-of-type {
                &::after {
                    display: none;
                }
            }
            &::after {
                content: '';
                background-image: url('/front/images/Elportal/icons/red-arrow-right.png');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 5px;
                height: 7.8px;
                display: inline-block;
                margin: 0 10px;
            }
        }
    }
}