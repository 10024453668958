.quiz-list {
    &__header {
        font-size: 30px;
        font-weight: 400;
        margin: 0 0 20px;
        text-transform: uppercase;
    }
    &__list {
        margin-bottom: 70px;
    }
}
