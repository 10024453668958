.user-info {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    background-color: #ffffff;
    z-index: 99;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0 0 30px rgba(0,0,0,0.16);
    display: none;
    max-width: 300px;
    &__wrap {
        margin-left: auto;
        margin-right: 0;
        justify-self: flex-end;
        position: relative;
        flex-grow: 1;
        justify-content: flex-end;
        display: flex;
        flex-wrap: wrap;
        * {
            user-select: none;
        }
        &.logged {
            .user-info {
                &__login {
                    display: none;
                }
                &__btn {
                    display: flex;
                }
            }
        }
        &.open.logged {
            .user-info {
                display: flex;
            }
        }
    }
    &__login {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        max-width: 135px;
        width: 100%;
        min-height: 33px;
        background-color: #F62023;
        color: #ffffff;
        border-radius: 167px;
        font-size: 12px;
        letter-spacing: 0.02em;
        font-weight: 400;
        text-transform: uppercase;
        img {
            margin-right: 10px;
        }
    }
    &__title {
        display: block;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #CD1719;
    }
    &__value {
        display: block;
        font-size: 15px;
        font-weight: 600;
    }
    &__btn {
        display: none;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        padding: 3px 18px;
        min-height: 33px;
        border: 2px solid #CD1719;
        border-radius: 167px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.02em;
        max-width: 175px;
        text-transform: uppercase;
        cursor: pointer;
        span {
            margin: 0 9px;
        }
    }
    &__name {
        width: 100%;
        margin-bottom: 19px;
    }
    &__email {
        width: 100%;
        margin-bottom: 19px;
        padding-bottom: 19px;
        border-bottom: 1px solid #DCDCDC;
    }
    &__code, &__date {
        width: calc(100% - 95px);
        margin: 0 0 19px;
    }
    &__status, &__days {
        width: 70px;
        margin: 0 0 19px;
    }
    &__subscription {
        width: 100%;
        max-width: 200px;
        margin-top: 5px;
        margin: 5px auto 0;
        min-height: 50px;
        display: flex;
        flex-wrap: wrap;
        background-color: #F62023;
        border-radius: 142px;
        color: #ffffff;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    &__logout {
        margin-top: 20px;
        border-top: 1px solid #DCDCDC;
        padding-top: 20px;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        cursor: pointer;
        img {
            margin-left: 11px;
        }
    }
}
.user-info__wrap--header {
    @media(min-width: 1025px) {
        display: none;
    }
    .user-info {
        @media(max-width: 576px) {
            right: -45px;
        }
        &__btn {
            display: none;
            border: none;
            padding: 0;
            margin: 0;
            min-height: auto;
            width: auto;
            margin: -4px 15px 0;
        }
        &__login {
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            min-height: auto;
            width: auto;
            margin: -4px 15px 0;
            img {
                margin: 0;
            }
        }
        &__wrap {
            &.logged {
                .user-info {
                    &__btn {
                        display: block;
                    }
                    &__login {
                        display: none;
                    }
                }
            }
        }
    }
}
.top-bar {
    .user-info__wrap {
        display: none;
        @media(min-width: 1025px) {
            display: flex;
        }
    }
}
