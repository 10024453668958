.course-hp {
    margin-bottom: 30px;
    display: block;
    &__wrapper {
        background-color: #fff;
        filter: drop-shadow(0 10px 50px rgba(0,0,0,.16));
        padding: 20px;
        &-title {
            font-size: 20px;
            line-height: 26px;
            font-weight: 700;
            letter-spacing: -0.025em;
            margin-bottom: 20px;
        }
        &-image {
            margin-bottom: 20px;
            img {
                display: block;
            }
        }
        &-description {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 21px;
        }
        &-btn {
            display: block;
            max-width: 266px;
            margin: 0 auto;
            border-radius: 142px;
            color: #fff;
            text-transform: uppercase;
            padding: 20px;
            text-align: center;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.050em;
            font-weight: 500;
            background-color: #CD1719;
        }
    }
}