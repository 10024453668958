.questionnaire-page {
    counter-reset: questionnaire;
    #questionnaire {
        width: 100%;
    }
    &__gift {
        background:-moz-linear-gradient(45deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
        background:-webkit-linear-gradient(45deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
        background:-o-linear-gradient(45deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1F1F1F', endColorstr='#1C4175', GradientType=0 );
        background:-ms-linear-gradient(45deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
        background:linear-gradient(45deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
        width: 100%;
        max-width: 970px;
        margin-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        color: #ffffff;
        position: relative;
        overflow: hidden;
        padding: 30px 30px 22px;
        @media(min-width: 576px) {
            padding: 30px 56px 22px;
        }
        &:after {
            content: "";
            position: absolute;
            width: 848px;
            height: 509px;
            background-image: url("/front/images/Elportal/subscription/bg.png");
            top: -40px;
            right: -450px;
        }
        &-image {
            width: 100%;
            margin: 0 0 30px;
            position: relative;
            z-index: 3;
            @media(min-width: 768px) {
                width: auto;
                margin: 0;
            }
            img {
                display: block;
                max-width: 100%;
                height: auto;
                margin: 0 auto;
            }
        }
        &-text {
            font-size: 20px;
            font-weight: 400;
            letter-spacing: -0.025em;
            width: 100%;
            text-align: center;
            position: relative;
            z-index: 3;
            @media(min-width: 768px) {
                width: auto;
                text-align: left;
                padding-left: 70px;
            }
            p {
                margin: 0 0 10px;
                padding: 0;
                &:last-of-type {
                    margin: 0 0 30px;
                }
            }
            strong {
                font-weight: 700;
            }
        }
        &-btn {
            width: 206px;
            height: 58px;
            background-color: #F62023;
            border-radius: 203px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 17px;
            font-weight: 500;
            letter-spacing: 0.05em;
            margin: 0 auto;
        }
    }
    &__message {
        min-height: 90px;
        padding: 0 35px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin-bottom: 30px;
        color: #ffffff;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -0.025em;
        position: relative;
        &.closed {
            display: none;
        }
        &--success {
            background-color: #6CC981;
        }
        &--alert {
            background-color: #CD292A;
        }
        &-close {
            position: absolute;
            width: 29px;
            height: 29px;
            top: 0;
            bottom: 0;
            right: 25px;
            margin: auto;
            cursor: pointer;
            img {
                max-width: 100%;
                height: auto;
                display: block;
                pointer-events: none;
            }
        }
    }
    &__title {
        font-weight: 700;
        letter-spacing: -0.025em;
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        max-width: 720px;
        background-color: #F3F3F3;
        position: relative;
        z-index: 3;
        font-size: 30px;
        @media(min-width: 576px) {
            font-size: 36px;
        }
        @media(min-width: 768px) {
            font-size: 40px;
        }
        @media(min-width: 1025px) {
            font-size: 47px;
        }
    }
    &__description {
        font-weight: 700;
        letter-spacing: -0.025em;
        text-align: center;
        width: 100%;
        max-width: 750px;
        margin-bottom: 50px;
        font-size: 18px;
        line-height: 26px;
        @media(min-width: 576px) {
            font-size: 20px;
            line-height: 30px;
        }
        @media(min-width: 768px) {
            font-size: 22px;
            line-height: 33px;
        }
        @media(min-width: 1025px) {
            font-size: 24px;
            line-height: 35px;
            padding: 0 15px;
        }
    }
    &__top {
        margin-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: 25px;
            left: 0;
            bottom: 0;
            right: 0;
            border: 3px solid #ffffff;
            pointer-events: none;
            display: none;
            @media(min-width: 768px) {
                display: block;
            }
        }
        &-left,
        &-right {
            width: 100%;
            @media(min-width: 1025px) {
                width: auto;
            }
        }
        &-left {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            margin-bottom: 30px;
            width: 100%;
            @media(min-width: 576px) {
                width: auto;
            }
            @media(min-width: 1025px) {
                margin-bottom: 0;
            }
        }
        &-right {
            text-align: center;
            @media(min-width: 576px) {
                text-align: left;
            }
            @media(min-width: 1025px) {
                text-align: right;
            }
        }
    }
    &__quantity {
        font-size: 20px;
        letter-spacing: -0.025em;
        font-weight: 400;
        margin-bottom: 10px;
        span {
            font-size: 23px;
            letter-spacing: -0.025em;
            font-weight: 700;
        }
    }
    &__time {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    &__uk {
        &-image {
            width: 100%;
            margin: 0 0 30px;
            @media(min-width: 576px) {
                margin: 0;
                width: auto;
                max-width: 124px;
            }
            img {
                display: block;
                max-width: 100%;
                height: auto;
                margin: 0 auto;
            }
        }
        &-description {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.05em;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        &-title {
            font-size: 23px;
            font-weight: 700;
            letter-spacing: -0.025em;
            margin-bottom: 10px;
        }
        &-date {
            font-size: 20px;
            letter-spacing: -0.025em;
            font-weight: 400;
        }
        &-text {
            width: 100%;
            text-align: center;
            @media(min-width: 576px) {
                width: auto;
                text-align: left;
                margin-left: 40px;
            }
        }
    }
    &__form {
        &-top {
            border-radius: 3px;
            margin: 0 0 50px;
            @media(min-width: 576px) {
                border: 2px solid #EAEAEB;
                padding: 15px;
            }
            @media(min-width: 768px) {
                padding: 30px;
            }
            @media(min-width: 1025px) {
                padding: 50px;
            }
        }
        &-bottom {
            position: relative;
            input[type="email"] {
                width: 100%;
                height: 100px;
                border-radius: 3px;
                border: 2px solid #EAEAEB;
                margin: 0 0 30px;
                padding: 0 15px;
                font-size: 18px;
                @media(min-width: 768px) {
                    padding: 0 30px;
                    font-size: 20px;
                    margin: 0;
                }
                @media(min-width: 1025px) {
                    padding: 0 255px 0 50px;
                    font-size: 24px;
                }
                &::placeholder {
                    opacity: 1;
                }
            }
            button[type="submit"] {
                width: 100%;
                right: 35px;
                border-radius: 203px;
                background-color: #F62023;
                color: #ffffff;
                border: none;
                outline: none;
                font-size: 17px;
                font-weight: 500;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                height: 100px;
                cursor: pointer;
                @media(min-width: 768px) {
                    width: 205px;
                    height: 58px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
    }
    &__radio {
        margin: 0 0 50px;
        &:last-child {
            margin: 0;
        }
    }
    &__info {
        font-size: 12px;
        line-height: 17px;
        opacity: 0.5;
        .close {
            display: none;
        }
        .btn {
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
        }
        &.open {
            .close {
                display: inline;
            }
            .open {
                display: none;
            }
            .dots {
                display: none;
            }
        }
    }
    &__content {
        background-color: #ffffff;
        margin-bottom: 140px;
        padding: 30px 15px;
        @media(min-width: 768px) {
            padding: 30px;
        }
        @media(min-width: 1025px) {
            padding: 50px;
        }
        &-top {
            margin-bottom: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }
        & > form {
            margin: 0 0 30px;
            fieldset {
                border: none;
                outline: none;
                display: block;
                width: 100%;
                padding: 0;
                & > legend {
                    font-weight: 500;
                    letter-spacing: -0.025em;
                    margin-bottom: 25px;
                    font-size: 18px;
                    line-height: 24px;
                    @media(min-width: 768px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    @media(min-width: 1025px) {
                        font-size: 24px;
                        line-height: 35px;
                    }
                    &:before {
                        counter-increment: questionnaire;
                        content: counter(questionnaire) ". "
                    }
                }
                & > div[id] {
                    display: flex;
                    flex-wrap: wrap;
                }
                .form-check {
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                    overflow: hidden;
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;
                    &:last-child {
                        margin-right: 0;
                    }
                    input {
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        padding: 0;
                        cursor: pointer;
                        &:checked ~ label {
                            background-color: #F62023;
                            color: #ffffff;
                        }
                    }
                    label {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        background-color: #F4F4F4;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
