.newsletter-subscribe {
    background:-moz-linear-gradient(135deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
    background:-webkit-linear-gradient(135deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
    background:-o-linear-gradient(135deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1F1F1F', endColorstr='#1C4175', GradientType=0 );
    background:-ms-linear-gradient(135deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
    background:linear-gradient(135deg, rgba(28, 65, 117, 1) 0%, rgba(31, 31, 31, 1) 100%);
    color: #ffffff;
    overflow: hidden;
    text-align: center;
    margin: 0 auto 30px;
    position: relative;
    &--wide {
        max-width: 370px;
        padding: 20px 15px 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        @media(min-width: 1200px) {
            justify-content: space-between;
            padding: 30px;
            max-width: none;
        }
        &:after {
            content: "";
            position: absolute;
            background-image: url("/front/images/Elportal/newsletter/bg.png");
            width: 370px;
            height: 615px;
            bottom: -450px;
            left: 0;
            opacity: 0.1;
            @media(min-width: 1200px) {
                width: 848px;
                height: 509px;
                background-image: url("/front/images/Elportal/subscription/bg.png");
                top: -40px;
                right: -450px;
                bottom: auto;
                left: auto;
                opacity: 1;
            }
        }
        .newsletter-subscribe {
            &__top,
            &__form,
            &__paper {
                @media(min-width: 1200px) {
                    margin: 0;
                }
            }
            &__top {
                order: 1;
            }
            &__form {
                order: 2;
                @media(min-width: 1200px) {
                    order: 3;
                }
                &-email {
                    background-color: #303843;
                }
            }
            &__paper {
                order: 3;
                @media(min-width: 1200px) {
                    order: 2;
                }
            }
        }
    }
    &--small {
        padding: 20px 15px 15px;
        max-width: 370px;
        &:after {
            content: "";
            position: absolute;
            background-image: url("/front/images/Elportal/newsletter/bg.png");
            width: 370px;
            height: 615px;
            bottom: -450px;
            left: 0;
            opacity: 0.1;
        }
    }
    &--large {
        padding: 35px 15px 80px;
        max-width: 370px;
        &:after {
            content: "";
            position: absolute;
            background-image: url("/front/images/Elportal/newsletter/bg.png");
            width: 370px;
            height: 615px;
            top: 200px;
            left: 0;
            opacity: 0.1;
        }
        .newsletter-subscribe {
            &__form {
                margin-bottom: 90px;
            }
        }
    }
    &__top,
    &__form,
    &__paper {
        position: relative;
        z-index: 3;
    }
    &__top {
        margin-bottom: 15px;
        img {
            -webkit-filter: drop-shadow( 3px 30px 20px rgba(0, 0, 0, .7));
        }
    }
    &__form {
        position: relative;
        display: block;
        max-width: 250px;
        margin: 0 auto 25px;
        border-radius: 5px;
        overflow: hidden;
        &-email {
            border: none;
            outline: none;
            width: 100%;
            height: 43px;
            padding: 0 85px 0 15px;
            background-color: rgba(255,255,255,0.1);
            font-size: 14px;
            color: #ffffff;
            font-family: "Public Sans",sans-serif;
        }
        &-submit {
            position: absolute;
            top: 0;
            right: 0;
            background-color: #ffffff;
            height: 43px;
            width: 70px;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #F62023;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }
}
