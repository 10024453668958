.shop-slider {
    background-color: #fff;
    padding-top: 44px;
    padding-bottom: 37px;
    .swiper-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
    }
    .swiper-slide {
        max-width: 172px;
    }
    &__item {
        max-width: 172px;
        display: block;
        &-image {
            width: 170px;
            height: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 11px;
            img {
                max-width: 100%;
            }
        }
        &-title {
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            text-align: center;
        }
    }
    &__top {
        position: relative;
        &-more {
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.075em;
            text-align: right;
            color: #CD1719;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: 0;
            top: 0;
            img {
                margin-left: 8px;
            }
        }
    }
    &__navigation {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        &-next, &-prev {
            width: 235px;
            height: 100%;
            display: flex;
            align-items: center;
            transition: .3s all ease-in-out;
            &.hidden {
                opacity: 0;
                pointer-events: none;
            }
            &-wrapper {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #CD1719;
                border-radius: 50%;
                pointer-events: all;
                cursor: pointer;
                margin-bottom: 50px;
            }
        }
        &-next {
            background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            justify-content: flex-end;
        }
        &-prev {
            background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            text-align: left;
            justify-content: flex-start;
            img {
                transform: rotate(180deg);
            }
        }
    }
}