.contest {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 63px;
    display: block;
    margin-bottom: 30px;
    &__wrapper {
        background-color: #0E131B;
        border: 2px solid #FFFFFF;
        border-radius: 15px;
        color: #fff;
        width: 175px;
        height: 175px;
        text-align: center;
        margin: 0 auto;
        &-logo {
            text-align: center;
            padding-top: 25px;
            margin-bottom: 20px;
        }
        &-title {
            font-size: 28px;
            line-height: 30px;
            font-weight: 700;
            letter-spacing: -0.025em;
        }
    }
    &-small {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        padding: 12px;
        max-width: 330px;
        width: 100%;
        margin-bottom: 25px;
        &__wrapper {
            background-color: #0E131B;
            border: 2px solid #FFFFFF;
            color: #fff;
            border-radius: 7px;
            padding: 9.5px 21px;
            display: inline-block;
            font-size: 19px;
            font-weight: 700;
            letter-spacing: -0.025em;
        }
    }
}