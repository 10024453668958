.contact {
    &__wrapper {
        background-color: #fff;
        padding: 30px;
        margin-bottom: 60px;
        @media(max-width: 600px) {
            padding: 15px;
        }

        &-status-info {
            display: none;
            align-items: center;
            margin-bottom: 16px;
            font-size: 15px;
            line-height: 18px;
            font-weight: 700;
            color: rgb(0, 128, 0);

            &.error {
                color: #CD1719;

                img {
                    display: none;
                }

                img.error {
                    display: block;
                }
            }

            img {
                margin-right: 7px;
                display: block;

                &.error {
                    display: none;
                }
            }

            &.show {
                display: flex;
            }
        }

        &-top {
            margin-bottom: 60px;
        }

        &-subtitle {
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            letter-spacing: 0.115em;
            color: #CD1719;
            margin-bottom: 30px;
            text-transform: uppercase;

            &.--border {
                padding-bottom: 9px;
                border-bottom: 2px solid #E1E1E1;
            }
        }

        &-info {
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 30px;

            .bold {
                font-weight: 700;
            }
        }

        &-small-info {
            font-size: 13px;
            line-height: 25px;
        }

        &-item {
            margin-bottom: 30px;

            &-name {
                font-size: 16px;
                line-height: 25px;
                font-weight: 700;
                margin-bottom: 8px;
            }

            &-description {
                font-size: 13px;
                line-height: 20px;
                margin-bottom: 7px;
            }

            &-email {
                font-size: 13px;
                line-height: 20px;
                color: #CD1719 !important;
                display: block;
                margin-bottom: 8px;
            }

            &-phone {

            }
        }

        &-form {
            &-textarea {
                width: 100%;
                margin-bottom: 30px;

                textarea {
                    resize: none;
                    width: 100%;
                    height: 225px;
                    padding: 24px 19px;
                    font-size: 15px;
                    line-height: 18px;
                    font-family: 'Public Sans';
                    color: #898989;
                    border: 1px solid #DCDCDC;
                }
            }

            &-name {
                width: 100%;
                margin-bottom: 30px;

                input {
                    width: 100%;
                    font-size: 15px;
                    line-height: 18px;
                    font-family: 'Public Sans';
                    color: #898989;
                    border: 1px solid #DCDCDC;
                    padding: 22px 19px;
                }
            }

            &-bottom {
                display: flex;
                justify-content: space-between;

                &-recaptcha {

                }

                &-submit {
                    margin-bottom: 30px;

                    input {
                        font-size: 15px;
                        line-height: 18px;
                        color: #fff;
                        font-weight: 500;
                        max-width: 256px;
                        width: 100%;
                        padding: 22px;
                        background-color: #CD1719;
                        border-radius: 153px;
                        border: none;
                        text-transform: uppercase;
                        font-family: 'Public Sans', sans-serif;
                        cursor: pointer;

                        &[disabled] {
                            opacity: 0.6;
                            cursor: default;
                        }
                    }
                }
            }
        }
    }
}
