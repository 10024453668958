.kityavt-single {
    &__download {
        background-color: $color-white;
        border: 4px solid $color-font-main;
        color: $color-font-main;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        border-radius: 15px;
        margin-bottom: 60px;
        padding: 20px;
        @media(min-width: 576px) {
            padding: 24px 48px 24px 28px;
        }

        &-icon {
            flex-grow: 1;
            max-width: 67px;
            margin-right: 30px;
        }

        &-wrap {
            flex-grow: 2;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            max-width: calc(100% - 106px);
        }

        &-text {
            font-size: 20px;
            font-weight: 800;
            width: 100%;
            @media(min-width: 576px) {
                margin-right: 30px;
                width: auto;
            }
        }
    }
}