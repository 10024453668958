.quiz-module {
    background-color: $color-white;
    box-shadow: 0 10px 50px rgba(0,0,0,0.16);
    position: relative;
    margin-bottom: 60px;
    padding: 40px 15px 35px;
    @media(min-width: 576px) {
        padding: 40px 30px 35px;
    }
    @media(min-width: 768px) {
        padding: 40px 60px 35px;
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin-bottom: 30px;
        justify-content: center;
        @media(min-width: 576px) {
            justify-content: flex-start;
        }
    }
    &__icon {
        margin-right: 15px;
        max-width: 52px;
        img {
            max-width: 100%;
            height: auto;
            display: block;
        }
    }
    &__text {
        max-width: calc(100% - 67px);
    }
    &__name {
        font-weight: 400;
        color: #CD1719;
        text-transform: uppercase;
        font-size: 15px;
        @media(min-width: 576px) {
            font-size: 18px;
        }
    }
    &__title {
        font-weight: 700;
        letter-spacing: -0.025em;
        font-size: 25px;
        @media(min-width: 576px) {
            font-size: 30px;
        }
    }
    &__form {
        &-submit {
            min-height: 80px;
            padding: 5px;
            max-width: 600px;
            width: 100%;
            margin: 0 auto;
            background-color: #CD1719;
            border-radius: 152px;
            text-transform: uppercase;
            color: #ffffff;
            border: none;
            outline: none;
            box-shadow: none;
            font-size: 17px;
            font-weight: 700;
            letter-spacing: 0.1em;
            font-family: "Public Sans",sans-serif;
            display: block;
            cursor: pointer;
        }
    }
    &__question {
        display: flex;
        flex-wrap: wrap;
        font-weight: 700;
        margin-bottom: 30px;
        font-size: 18px;
        @media(min-width: 576px) {
            font-size: 20px;
        }
        &-counter {
            display: block;
            width: 60px;
            letter-spacing: -0.025em;
        }
        &-text {
            display: block;
            width: 100%;
            max-width: calc(100% - 60px);
        }
    }
    &__answers {
        margin-bottom: 30px;
    }
    &__answer {
        background-color: $color-white;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin-bottom: 5px;
        position: relative;
        border: 1px solid #DCDCDC;
        &:last-child {
            margin-bottom: 0;
        }
        &-input {
            margin: 0;
            padding: 0;
            width: 22px;
            height: 22px;
            position: absolute;
            top: 15px;
            left: 15px;
            opacity: 0;
            &:checked ~ label {
                &:after {
                    border-color: #CD1719;
                }
                &:before {
                    content: "";
                    width: 12px;
                    height: 12px;
                    top: 21px;
                    left: 21px;
                    border-radius: 50%;
                    background-color: #CD1719;
                    position: absolute;
                }
            }
        }
        &-label {
            padding-left: 37px;
            width: 100%;
            padding: 15px 15px 15px 52px;
            min-height: 54px;
            cursor: pointer;
            font-size: 16px;
            @media(min-width: 576px) {
                font-size: 18px;
            }
            &:after {
                content: "";
                position: absolute;
                top: 15px;
                left: 15px;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                border: 1px solid #DCDCDC;
            }
        }
    }
    .quiz-single__link {
        margin: 0 auto 30px;
        @media(min-width: 576px) {
            position: absolute;
            top: 25px;
            right: 25px;
            margin: 0;
        }
    }
}
