.downloads {
    &__header {
        font-size: 40px;
        font-weight: 400;
        margin: 0 0 45px;
        text-transform: uppercase;
    }
    &__year {
        margin-bottom: 30px;
        &.active {
            .downloads__year-items {
                display: block;
            }
            .downloads__year-title {
                img {
                    transform: rotate(45deg);
                }
            }
        }
        &-title {
            font-size: 40px;
            font-weight: 400;
            padding-bottom: 20px;
            border-bottom: 3px solid #F62023;
            margin-bottom: 55px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            cursor: pointer;
            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }
        &-items {
            display: none;
        }
    }
    &__edition {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 45px;
        border-bottom: 1px solid #898989;
        margin-bottom: 45px;

        &-image {
            width: 100%;
            @media(min-width: 768px) {
                width: 270px;
            }
            img {
                display: block;
                max-width: 100%;
                height: auto;
                margin: 0 auto 45px;
                box-shadow: 0 20px 99px rgba(0,0,0,0.4);
                @media(min-width: 768px) {
                    margin: 0;
                }
            }
        }
        &-title {
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        &-list {
            width: 100%;
            @media(min-width: 768px) {
                padding-left: 30px;
                max-width: calc(100% - 270px);
            }
        }
        &-item {
            background-color: #fff;
            margin-bottom: 1px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            min-height: 76px;
            padding: 10px 15px 10px;
            @media(min-width: 1025px) {
                padding: 10px 30px 10px;
            }
            &-name {
                width: 100%;
                margin-bottom: 15px;
                @media(min-width: 576px) {
                    margin-bottom: 0;
                    max-width: calc(100% - 90px);
                }
            }
            &-download {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
                color: #898989;
                width: 100%;
                justify-content: flex-end;
                @media(min-width: 576px) {
                    justify-content: center;
                    width: auto;
                }
                img {
                    margin-right: 6px;
                }
            }
        }
    }
}
