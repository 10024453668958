.category-item {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
    margin-bottom: 10px;
    &.inactive {
        background-color: transparent;
        .category-item__text {
            opacity: 0.1;
        }
    }
    &__image {
        flex: 0 0 109px;
        max-width: 109px;
        display: flex;
        align-items: center;
        justify-content: center;
        &--tag {
            img {
                border-radius: 50%;
                box-shadow: 0 3px 18px rgba(0,0,0,.16);
            }
        }
        @media(max-width: 650px) {
            display: none;
        }
    }
    &__text {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        flex-grow: 1;
        padding: 21px 0;
        @media(max-width: 650px) {
            padding-left: 15px;
        }
        @media(max-width: 500px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    &__counter {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        padding: 22px;
        margin: 15px 0;
        border-left: 1px solid #DCDCDC;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 240px;
        width: 100%;
        @media(max-width: 650px) {
            max-width: 130px;
        }
        @media(max-width: 500px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}