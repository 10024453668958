.for-you {
    &__page-description {
        margin-bottom: 30px;
        border: 3px solid #1F1F1F;
        padding: 27px 27px 33px 27px;
        @media(max-width: 500px) {
            padding: 15px;
        }
        &.show {
            .for-you__page-description-top {
                margin-bottom: 20px;
            }
            .dotts {
                display: none;
            }
            .hidden {
                display: inline;
            }
            .show-more {
                display: none;
            }
            .for-you__page-description-fulltext {
                display: block;
            }
        }
        &-top {
            display: flex;
            align-items: center;
            @media(max-width: 800px) {
                flex-wrap: wrap;
            }
            &-image {
                width: 124px;
                height: 124px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #CD1719;
                max-width: 124px;
                flex: 0 0 124px;
                margin-right: 30px;
                border-radius: 50%;
                @media(max-width: 800px) {
                    margin: 0 auto 30px auto;
                }
            }
            &-introtext {
                font-size: 17px;
                line-height: 27px;
                @media(max-width: 800px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
                @media(max-width: 600px) {
                    font-size: 16px;
                    line-height: 26px;
                }
                .hidden {
                    display: none;
                }
                .show-more {
                    font-weight: 700;
                    text-transform: uppercase;
                    cursor: pointer;
                }
            }
        }
        &-fulltext {
            font-size: 17px;
            line-height: 27px;
            display: none;
            @media(max-width: 600px) {
                font-size: 16px;
                line-height: 26px;
            }
            .hide-description {
                font-weight: 700;
                cursor: pointer;
                text-transform: uppercase;
            }
            ol {
                font-weight: 700;
                margin-bottom: 30px;
            }
            p {
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}