.error-page-body {
    padding-top: 0px;
}
.error {
    &-header {
        height: 100px;
        background-color: #fff;
        filter: drop-shadow(0 3px 50px rgba(0,0,0,.16));
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    &-page {
        padding-top: 100px;
        background-image: url('/front/images/Elportal/error-background.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        .header__logo {
            display: inline-block;
            margin-bottom: 0px;
        }
    }
    &-text {
        margin-top: 50px;
        margin-bottom: 100px;
        @media(max-width: 600px) {
            padding: 0 15px;
            margin: 0px;
        }
        &__title {
            font-size: 35px;
            font-weight: 700;
            text-align: center;
            @media(max-width: 600px) {
                font-size: 25px;
            }
        }
        &__code {
            margin: 0px;
            font-size: 224px;
            line-height: 250px;
            font-weight: 700;
            color: #fff;
            filter: drop-shadow(16px 21px 36px rgba(0,0,0,.26));
            text-align: center;
            @media(max-width: 600px) {
                font-size: 140px;
                line-height: 166px;
            }
        }
        &__status-text {
            font-size: 50px;
            font-weight: 700;
            color: #fff;
            filter: drop-shadow(16px 21px 36px rgba(0,0,0,.26));
            text-align: center;
            margin-bottom: 20px;
            @media(max-width: 600px) {
                font-size: 40px;
            }
            @media(max-width: 450px) {
                font-size: 30px;
            }
        }
        &__description {
            font-size: 20px;
            line-height: 33px;
            max-width: 412px;
            margin: 0 auto 20px auto;
            text-align: center;
            a {
                color: #CD1719;
                text-decoration: underline;
            }
        }
        &__form {
            max-width: 412px;
            margin: 0 auto;
            width: 100%;
            position: relative;
            input {
                width: 100%;
                height: 59px;
                border: none;
                font-family: 'Public Sans',sans-serif;
                padding: 19px 26px;
                font-size: 17px;
                line-height: 19px;
                font-weight: 700;
            }
            button {
                position: absolute;
                right: 0;
                top: 0;
                background-color: #fff;
                width: 59px;
                height: 59px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                cursor: pointer;
            }
        }
    }
}