.firm-single {
    &__header {
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 15px;
        color: #CD1719;
        margin: 0 0 10px;
        border-bottom: 2px solid #DCDCDC;
    }

    &__subheader {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.075em;
        text-transform: uppercase;
        margin: 0 0 35px;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #DCDCDC;
        @media(min-width: 576px) {
            flex-wrap: nowrap;
        }

        &:hover {
            color: #CD1719;
        }

        &-title {
            flex-grow: 2;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            transition: color .2s ease-in-out;
            @media(min-width: 576px) {
                padding-right: 30px;
            }
        }

        &-date {
            color: $color-font-main;
            font-size: 14px;
            font-weight: 400;
            opacity: 0.5;
            width: 100%;
            margin-top: 10px;
            text-align: left;
            @media(min-width: 576px) {
                text-align: right;
                width: auto;
                margin-top: 0;
            }
        }
    }

    &__items {
        margin: 0 0 70px;
    }
}