.rate-number-sm {
    margin: 0 auto 30px;
    padding: 25px 30px;
    max-width: 370px;
    width: 100%;
    background-color: $color-white;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    &:after {
        content: "";
        top: 35px;
        bottom: 32px;
        left: 30px;
        right: 30px;
        position: absolute;
        border: 2px solid #CD1719;
    }
    &__top {
        margin-bottom: 22px;
        font-size: 17px;
        font-weight: 700;
        width: 270px;
        background-color: $color-white;
        position: relative;
        z-index: 3;
    }
    &__middle {
        position: relative;
        width: 100%;
        height: 202px;
        margin-bottom: 23px;
    }
    &__bottom {
        font-size: 15px;
        font-weight: 700;
        color: #CD1719;
        width: 270px;
        background-color: $color-white;
        position: relative;
        z-index: 3;
        span {
            text-decoration: underline;
        }
    }
    &__papers {
        margin: 0 auto;
        display: block;
        max-width: 100%;
        height: auto;
    }
    &__paper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        display: block;
        max-width: 100%;
        height: auto;
        box-shadow: 0 30px 60px rgba(0,0,0,0.32);
        z-index: 6;
    }
}
