.projects {
    &__title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        padding-left: 15px;
        margin-bottom: 20px;
        text-transform: uppercase;
        @media(max-width: 650px) {
            padding-left: 0;
        }
    }
    &__counter {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        text-align: right;
        margin: auto 0 auto auto;
        max-width: 240px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        @media(max-width: 650px) {
            text-align: right;
            max-width: 170px;
        }
    }
    &__item {
        background-color: #fff;
        display: block;
        padding: 10px 15px 15px 15px;
        text-align: center;
        height: 100%;
        &-diy {
            position: absolute;
            bottom: 10px;
            left: 10px;
        }
        &-image {
            margin-bottom: 15px;
            position: relative;
            display: flex;
            justify-content: center;
            &-kit {
                position: absolute;
                display: inline-block;
                bottom: 0;
                padding: 5px;
                font-size: 11px;
                line-height: 13px;
                letter-spacing: 0.020em;
                color: #fff;
                background-color: #CD1719;
            }
        }
        &-title {
            font-size: 19px;
            line-height: 24px;
            font-weight: 700;
            margin-bottom: 17px;
        }
        &-hits {
            color: #898989;
            font-size: 13px;
            line-height: 24px;
        }
    }
}
