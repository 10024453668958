@import "../node_modules/simplebar/src/simplebar";

.simplebar {
    &-scrollbar {
        &::before {
            width: 5px;
            background-color: #1F1F1F;
            opacity: 1 !important;
        }
    }
    &-track {
        &::after {
            content: '';
            height: 99%;
            width: 1px;
            background: #DDDDDD;
            position: absolute;
            left: 4px;
            top: 3px;
            z-index: -1;
        }
    }

    &-content {
        margin-bottom: 100px;
    }
}
.subpage-category-select {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    min-height: 83px;
    margin-bottom: 3px;
    &.lower-case {
        .category-select__title-value {
            text-transform: none;
        }
    }
    &.reading {
        .category-select__title-value {
            font-size: 15px;
        }
        .category-select {
            max-width: 270px;
            right: 0;
            left: auto;
        }
        .category-select__wrapper {
            height: 150px;
        }
        .simplebar-mask {
            &::after {
                display: none;
            }
        }
        .simplebar-content {
            margin-bottom: 0px;
        }
    }
    .category-select {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 6;
        background-color: transparent;
        transition: .3s all ease-in-out;
        &__title {
            border-bottom: 2px solid #1F1F1F;
        }
        &.active {
            box-shadow: 0 10px 50px rgba(0,0,0,.16);
            background-color: #fff;
            .category-select__wrapper {
                max-height: 420px;
                display: block;
            }
            .category-select__title-btn img {
                transform: rotate(180deg);
            }
        }
        &__wrapper {
            height: 420px;
            max-height: 0px;
            transition: .3s all ease-in-out;
            display: none;
        }
    }
}
.category-select {
    padding: 20px 30px;
    background-color: #fff;
    margin-bottom: 15px;
    &.shadow {
        box-shadow: 0 10px 50px rgba(0,0,0,.16);
    }
    &.reading {
        .cat {
            &-344, &-345, &-348, &-349, &-351 {
                display: none;
            }
        }
    }
    &__title {
        font-size: 18px;
        letter-spacing: 0.020em;
        font-weight: 700;
        padding-bottom: 10px;
        border-bottom: 2px solid #CD1719;
        margin-bottom: 20px;
        text-transform: uppercase;
        &.flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-btn {
            width: 28px;
            height: 28px;
            flex: 0 0 28px;
            border-radius: 50%;
            background-color: #CD1719;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                transition: .3s all ease-in-out;
            }
        }
    }
    &__wrapper {
        height: 520px;
        // display: none;
        &-item {
            display: block;
            font-size: 15px;
            line-height: 30px;
            transition: .3s all ease-in-out;
            &:hover {
                color: #CD1719;
            }
            &.active {
                color: #CD1719;
                text-decoration: underline;
            }
            &.inactive {
                color: #DCDCDC;
                cursor: auto;
            }
        }
    }
}