.kityavt-list {
    .breadcrumb {
        margin-bottom: 10px;
    }

    &__header {
        text-transform: uppercase;
        font-weight: 400;
        margin: 0 0 5px;
        font-size: 30px;
        line-height: 40px;
        @media(min-width: 576px) {
            font-size: 35px;
            line-height: 45px;
        }
        @media(min-width: 1025px) {
            font-size: 40px;
            line-height: 53px;
        }
    }

    &__items {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media(min-width: 768px) {
            display: block;
        }

        &--last {
            margin-bottom: 90px;
        }

        &-header {
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 20px;
            font-size: 22px;
            @media(min-width: 576px) {
                font-size: 25px;
            }
        }
    }

    &__item {
        background-color: $color-white;
        color: $color-font-main;
        display: flex;
        align-items: center;
        align-content: center;
        font-weight: 500;
        padding: 15px;
        flex-wrap: wrap;
        font-size: 15px;
        justify-content: center;
        margin: 0 0 15px;
        width: 100%;
        max-width: 300px;
        @media(min-width: 576px) {
            font-size: 16px;
            margin: 0 7.5px 15px;
        }
        @media(min-width: 768px) {
            max-width: none;
            justify-content: flex-start;
            flex-wrap: nowrap;
            padding: 18px 40px 18px 15px;
            margin: 0 0 3px;
        }

        &-counter {
            margin-right: 10px;
            text-align: center;
            font-size: 15px;
            @media(min-width: 768px) {
                font-size: 20px;
                width: 45px;
                min-width: 45px;
            }
        }

        &-title {
            width: 100%;
            margin: 8px 0;
            text-align: center;
            @media(min-width: 768px) {
                width: auto;
                text-align: left;
                margin: 0 20px 0 0;
            }
        }

        &-id {
            background-color: #CD1719;
            color: $color-white;
            font-weight: 400;
            padding: 5px;
            font-size: 12px;
            @media(min-width: 576px) {
                font-size: 16px;
            }
            @media(min-width: 768px) {
                margin-right: 20px;
            }
        }

        &-links {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            @media(min-width: 768px) {
                margin-left: auto;
                margin-right: 0;
            }
        }

        &-link {
            color: #D95051;
            font-weight: 700;
            text-transform: uppercase;
            white-space: nowrap;
            font-size: 13px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            @media(min-width: 576px) {
                font-size: 15px;
            }

            &:first-child {
                &:after {
                    content: "";
                    width: 1px;
                    height: 36px;
                    background-color: #DEDEDE;
                    display: block;
                    margin: 0 15px;
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &--other {
                font-weight: 500;
            }
        }
    }
}