.videos {
    margin-top: 100px;
    margin-bottom: 30px;
    background-color: #1F1F1F;
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    &__image {
        img {
            margin-top: -70px;
            box-shadow: 0 30px 30px rgba(0, 0, 0, 0.6);
        }
    }
    &__text {
        margin: 20px 15px;
        strong {
            transition: color 0.2s ease-in-out;
            &:hover {
                color: #cd1719;
            }
        }
    }
    &__link {
        width: fit-content;
        margin: 0 auto;
        font-size: 13px;
        color: #818181;
        text-transform: uppercase;
        padding-bottom: 30px;
        transition: color 0.2s ease-in-out;
        &:hover {
            color: #ffffff
        }
        img {
            display: block;
            margin: 10px auto;
        }
    }
}