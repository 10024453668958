.pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 60px auto;
    width: 100%;
    // padding: 0 15px;
    &__prev {
        img {
            transform: rotate(180deg);
        }
    }
    &__prev, &__next {
        background-color: #CD1719;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media(max-width: 400px) {
            width: 40px;
            height: 40px;
        }
        &.inactive {
            cursor: initial;
            background-color: #898989;
        }
    }
    &__items {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin: 0 10px;
            @media(max-width: 850px) {
                display: none;
            }
            @media(max-width: 400px) {
                width: 30px;
                height: 30px;
            }
            &.next-page, &.prev-page, &.inactive {
                @media(max-width: 850px) {
                    display: flex;
                }
            }
            &.inactive {
                background-color: #1F1F1F;
                border-radius: 209px;
                color: #fff;
            }
        }
        .page-separator {
            @media(max-width: 850px) {
                display: none;
            }
        }
    }
}
