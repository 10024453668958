.quiz-single {
    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
    }
    &__name {
        margin-bottom: 10px;
        font-size: 28px;
        font-weight: 400;
        text-transform: uppercase;
    }
    &__link {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        max-width: 200px;
        min-height: 30px;
        border-radius: 140px;
        border: 1px solid #363636;
        padding: 5px;
        font-size: 11px;
    }
    &__title {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 2px solid #DCDCDC;
    }
    &__introtext {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.025em;
        margin-bottom: 30px;
        a {
            color: #CD1719;
            text-decoration: none;
        }
    }
    &__summary {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    &__form {
        &-submit {
            width: 100%;
            max-width: 770px;
            margin: 0 auto 50px;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: #CD1719;
            border-radius: 152px;
            color: $color-white;
            text-transform: uppercase;
            min-height: 80px;
            font-size: 17px;
            font-weight: 700;
            letter-spacing: 0.1em;
            cursor: pointer;
            display: block;
        }
        &-answer {
            background-color: $color-white;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            margin-bottom: 5px;
            position: relative;
            &.selected {
                border: 2px solid #FF0000;
                &.correct {
                    border: 2px solid #34B94E;
                }
            }
            &.correct {
                border: 2px solid #34B94E;
            }
            &:last-child {
                margin-bottom: 0;
            }
            input {
                margin: 0;
                padding: 0;
                width: 22px;
                height: 22px;
                position: absolute;
                top: 15px;
                left: 15px;
                opacity: 0;
                &:checked ~ label {
                    &:after {
                        border-color: #CD1719;
                    }
                    &:before {
                        content: "";
                        width: 12px;
                        height: 12px;
                        top: 21px;
                        left: 21px;
                        border-radius: 50%;
                        background-color: #CD1719;
                        position: absolute;
                    }
                }
            }
            label {
                padding-left: 37px;
                font-size: 18px;
                width: 100%;
                padding: 15px 15px 15px 52px;
                min-height: 54px;
                cursor: pointer;
                &:after {
                    content: "";
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    border: 1px solid #DCDCDC;
                }
            }
        }
        &-question {
            position: relative;
            margin-bottom: 30px;
            @media(min-width: 1025px) {
                padding-left: 90px;
            }
            &:last-child {
                margin-bottom: 50px;
            }
            &:after {
                content: attr(data-counter);
                font-size: 20px;
                font-weight: 700;
                letter-spacing: -0.025em;
                position: absolute;
                top: 0;
                left: 0;
            }
            &-text {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 30px;
                padding-left: 45px;
                @media(min-width: 1025px) {
                    padding-left: 0;
                }
            }

        }
    }
}
