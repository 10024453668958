@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.main-bs-right {
    & > .farnell-search,
    .farnell-search {
        &__wrap {
            z-index: 5;
        }
    }
}
.farnell-search {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    background-color: $color-white;
    border: 1px solid #CDCDCD;
    padding: 12px 0 5px;
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: $color-white;
        padding: 34px 0 44px;
        margin: 0 auto 30px;
        max-width: 385px;
    }
    &__result {
        position: absolute;
        top: 40px;
        border: 1px solid #CDCDCD;
        border-top: none;
        background-color: $color-white;
        opacity: 0;
        pointer-events: none;
        max-height: 440px;
        overflow: auto;
        &-item {
            font-size: 14px;
            line-height: 18px;
            padding: 5px 5px 5px 15px;
            display: block;
            &:hover {
                color: $color-font-main;
                background-color: rgba(0,0,0,0.15);
            }
        }
    }
    &__logo {
        margin-bottom: 13px;
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
    &__bg {
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
    &__description {
        font-family: Arial, sans-serif;
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 15px;
    }
    &__form {
        position: relative;
        max-width: 282px;
        margin: 0 auto;
        z-index: 9;
        &.result {
            .farnell-search {
                &__result {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
        &.search {
            .farnell-search {
                &__input {
                    &-img {
                        animation-duration: 1s;
                        animation-name: spin;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;
                    }
                }
            }
        }
    }
    &__input {
        height: 40px;
        width: 100%;
        margin: 0 auto;
        border: 1px solid #4BB061;
        font-size: 14px;
        padding: 0 35px 0 15px;
        &:focus,
        &:active {
            outline: none;
        }
        &-wrap {
            position: relative;
            margin: 0 auto 4px;
            width: 100%;
        }
        &-img {
            position: absolute;
            top: 12px;
            right: 13px;
            transform: rotate(0deg);
            transition-duration: 1s;
        }
    }

}
