/* PrismJS 1.23.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript+abap+abnf+actionscript+ada+agda+al+antlr4+apacheconf+apex+apl+applescript+aql+arduino+arff+asciidoc+aspnet+asm6502+autohotkey+autoit+bash+basic+batch+bbcode+birb+bison+bnf+brainfuck+brightscript+bro+bsl+c+csharp+cpp+cfscript+chaiscript+cil+clojure+cmake+cobol+coffeescript+concurnas+csp+coq+crystal+css-extras+csv+cypher+d+dart+dataweave+dax+dhall+diff+django+dns-zone-file+docker+dot+ebnf+editorconfig+eiffel+ejs+elixir+elm+etlua+erb+erlang+excel-formula+fsharp+factor+false+firestore-security-rules+flow+fortran+ftl+gml+gcode+gdscript+gedcom+gherkin+git+glsl+go+graphql+groovy+haml+handlebars+haskell+haxe+hcl+hlsl+http+hpkp+hsts+ichigojam+icon+icu-message-format+idris+ignore+inform7+ini+io+j+java+javadoc+javadoclike+javastacktrace+jexl+jolie+jq+jsdoc+js-extras+json+json5+jsonp+jsstacktrace+js-templates+julia+keyman+kotlin+kumir+latex+latte+less+lilypond+liquid+lisp+livescript+llvm+log+lolcode+lua+makefile+markdown+markup-templating+matlab+mel+mizar+mongodb+monkey+moonscript+n1ql+n4js+nand2tetris-hdl+naniscript+nasm+neon+nevod+nginx+nim+nix+nsis+objectivec+ocaml+opencl+openqasm+oz+parigp+parser+pascal+pascaligo+psl+pcaxis+peoplecode+perl+php+phpdoc+php-extras+plsql+powerquery+powershell+processing+prolog+promql+properties+protobuf+pug+puppet+pure+purebasic+purescript+python+qsharp+q+qml+qore+r+racket+jsx+tsx+reason+regex+rego+renpy+rest+rip+roboconf+robotframework+ruby+rust+sas+sass+scss+scala+scheme+shell-session+smali+smalltalk+smarty+sml+solidity+solution-file+soy+sparql+splunk-spl+sqf+sql+squirrel+stan+iecst+stylus+swift+t4-templating+t4-cs+t4-vb+tap+tcl+tt2+textile+toml+turtle+twig+typescript+typoscript+unrealscript+uri+v+vala+vbnet+velocity+verilog+vhdl+vim+visual-basic+warpscript+wasm+wiki+xeora+xml-doc+xojo+xquery+yaml+yang+zig&plugins=line-highlight+line-numbers+inline-color+toolbar+copy-to-clipboard */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

 code[class*="language-"],
 pre[class*="language-"] {
     color: black;
     background: none;
     text-shadow: 0 1px white;
     font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
     font-size: 1em;
     text-align: left;
     white-space: pre;
     word-spacing: normal;
     word-break: normal;
     word-wrap: normal;
     line-height: 1.5;
 
     -moz-tab-size: 4;
     -o-tab-size: 4;
     tab-size: 4;
 
     -webkit-hyphens: none;
     -moz-hyphens: none;
     -ms-hyphens: none;
     hyphens: none;
 }
 
 pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
 code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
     text-shadow: none;
     background: #b3d4fc;
 }
 
 pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
 code[class*="language-"]::selection, code[class*="language-"] ::selection {
     text-shadow: none;
     background: #b3d4fc;
 }
 
 @media print {
     code[class*="language-"],
     pre[class*="language-"] {
         text-shadow: none;
     }
 }
 
 /* Code blocks */
 pre[class*="language-"] {
     padding: 1em;
     margin: .5em 0;
     overflow: auto;
 }
 
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
     background: #f5f2f0;
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
     padding: .1em;
     border-radius: .3em;
     white-space: normal;
 }
 
 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
     color: slategray;
 }
 
 .token.punctuation {
     color: #999;
 }
 
 .token.namespace {
     opacity: .7;
 }
 
 .token.property,
 .token.tag,
 .token.boolean,
 .token.number,
 .token.constant,
 .token.symbol,
 .token.deleted {
     color: #905;
 }
 
 .token.selector,
 .token.attr-name,
 .token.string,
 .token.char,
 .token.builtin,
 .token.inserted {
     color: #690;
 }
 
 .token.operator,
 .token.entity,
 .token.url,
 .language-css .token.string,
 .style .token.string {
     color: #9a6e3a;
     /* This background color was intended by the author of this theme. */
     background: hsla(0, 0%, 100%, .5);
 }
 
 .token.atrule,
 .token.attr-value,
 .token.keyword {
     color: #07a;
 }
 
 .token.function,
 .token.class-name {
     color: #DD4A68;
 }
 
 .token.regex,
 .token.important,
 .token.variable {
     color: #e90;
 }
 
 .token.important,
 .token.bold {
     font-weight: bold;
 }
 .token.italic {
     font-style: italic;
 }
 
 .token.entity {
     cursor: help;
 }
 
 pre[data-line] {
     position: relative;
     padding: 1em 0 1em 3em;
 }
 
 .line-highlight {
     position: absolute;
     left: 0;
     right: 0;
     padding: inherit 0;
     margin-top: 1em; /* Same as .prism’s padding-top */
 
     background: hsla(24, 20%, 50%,.08);
     background: linear-gradient(to right, hsla(24, 20%, 50%,.1) 70%, hsla(24, 20%, 50%,0));
 
     pointer-events: none;
 
     line-height: inherit;
     white-space: pre;
 }
 
 @media print {
     .line-highlight {
         /*
          * This will prevent browsers from replacing the background color with white.
          * It's necessary because the element is layered on top of the displayed code.
          */
         -webkit-print-color-adjust: exact;
         color-adjust: exact;
     }
 }
 
     .line-highlight:before,
     .line-highlight[data-end]:after {
         content: attr(data-start);
         position: absolute;
         top: .4em;
         left: .6em;
         min-width: 1em;
         padding: 0 .5em;
         background-color: hsla(24, 20%, 50%,.4);
         color: hsl(24, 20%, 95%);
         font: bold 65%/1.5 sans-serif;
         text-align: center;
         vertical-align: .3em;
         border-radius: 999px;
         text-shadow: none;
         box-shadow: 0 1px white;
     }
 
     .line-highlight[data-end]:after {
         content: attr(data-end);
         top: auto;
         bottom: .4em;
     }
 
 .line-numbers .line-highlight:before,
 .line-numbers .line-highlight:after {
     content: none;
 }
 
 pre[id].linkable-line-numbers span.line-numbers-rows {
     pointer-events: all;
 }
 pre[id].linkable-line-numbers span.line-numbers-rows > span:before {
     cursor: pointer;
 }
 pre[id].linkable-line-numbers span.line-numbers-rows > span:hover:before {
     background-color: rgba(128, 128, 128, .2);
 }
 
 pre[class*="language-"].line-numbers {
     position: relative;
     padding-left: 3.8em;
     counter-reset: linenumber;
 }
 
 pre[class*="language-"].line-numbers > code {
     position: relative;
     white-space: inherit;
 }
 
 .line-numbers .line-numbers-rows {
     position: absolute;
     pointer-events: none;
     top: 0;
     font-size: 100%;
     left: -3.8em;
     width: 3em; /* works for line-numbers below 1000 lines */
     letter-spacing: -1px;
     border-right: 1px solid #999;
 
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
 
 }
 
     .line-numbers-rows > span {
         display: block;
         counter-increment: linenumber;
     }
 
         .line-numbers-rows > span:before {
             content: counter(linenumber);
             color: #999;
             display: block;
             padding-right: 0.8em;
             text-align: right;
         }
 
 span.inline-color-wrapper {
     /*
      * The background image is the following SVG inline in base 64:
      *
      * <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2">
      *     <path fill="gray" d="M0 0h2v2H0z"/>
      *     <path fill="white" d="M0 0h1v1H0zM1 1h1v1H1z"/>
      * </svg>
      *
      * SVG-inlining explained:
      * https://stackoverflow.com/a/21626701/7595472
      */
     background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyIDIiPjxwYXRoIGZpbGw9ImdyYXkiIGQ9Ik0wIDBoMnYySDB6Ii8+PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik0wIDBoMXYxSDB6TTEgMWgxdjFIMXoiLz48L3N2Zz4=");
     /* This is to prevent visual glitches where one pixel from the repeating pattern could be seen. */
     background-position: center;
     background-size: 110%;
 
     display: inline-block;
     height: 1.333ch;
     width: 1.333ch;
     margin: 0 .333ch;
     box-sizing: border-box;
     border: 1px solid white;
     outline: 1px solid rgba(0,0,0,.5);
     overflow: hidden;
 }
 
 span.inline-color {
     display: block;
     /* To prevent visual glitches again */
     height: 120%;
     width: 120%;
 }
 
 div.code-toolbar {
     position: relative;
 }
 
 div.code-toolbar > .toolbar {
     position: absolute;
     top: .3em;
     right: .2em;
     transition: opacity 0.3s ease-in-out;
     opacity: 0;
 }
 
 div.code-toolbar:hover > .toolbar {
     opacity: 1;
 }
 
 /* Separate line b/c rules are thrown out if selector is invalid.
    IE11 and old Edge versions don't support :focus-within. */
 div.code-toolbar:focus-within > .toolbar {
     opacity: 1;
 }
 
 div.code-toolbar > .toolbar .toolbar-item {
     display: inline-block;
 }
 
 div.code-toolbar > .toolbar a {
     cursor: pointer;
 }
 
 div.code-toolbar > .toolbar button {
     background: none;
     border: 0;
     color: inherit;
     font: inherit;
     line-height: normal;
     overflow: visible;
     padding: 0;
     -webkit-user-select: none; /* for button */
     -moz-user-select: none;
     -ms-user-select: none;
 }
 
 div.code-toolbar > .toolbar a,
 div.code-toolbar > .toolbar button,
 div.code-toolbar > .toolbar span {
     color: #bbb;
     font-size: .8em;
     padding: 0 .5em;
     background: #f5f2f0;
     background: rgba(224, 224, 224, 0.2);
     box-shadow: 0 2px 0 0 rgba(0,0,0,0.2);
     border-radius: .5em;
 }
 
 div.code-toolbar > .toolbar a:hover,
 div.code-toolbar > .toolbar a:focus,
 div.code-toolbar > .toolbar button:hover,
 div.code-toolbar > .toolbar button:focus,
 div.code-toolbar > .toolbar span:hover,
 div.code-toolbar > .toolbar span:focus {
     color: inherit;
     text-decoration: none;
 }
 
 