.slider-uk  {
    background-color: #1F1F1F;
    padding: 59px 15px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    &__title {
        font-size: 35px;
        letter-spacing: -0.030em;
        color: #fff;
        text-align: center;
        margin-bottom: 47px;
        max-width: 100%;
        width: 100%;
    }
    &__item {
        display: block;
        &-image {
            margin-bottom: 25px;
            position: relative;
            img {
                transition: .3s all ease-in-out;
                transform: scale(0.8035);
                opacity: 0.4;
                display: block;
                margin: 0 auto;
            }
        }
        &-text {
            opacity: 0;
            transition: .3s all ease-in-out;
            max-width: 300px;
            &-title {
                font-size: 30px;
                color: #fff;
                text-align: center;
                margin-bottom: 20px;
            }
            &-date {
                font-size: 13px;
                letter-spacing: 0.100em;
                color: rgba(255,255,255,.5);
                text-align: center;
                margin-bottom: 20px;
            }
            &-btn {
                color: #fff;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.050em;
                max-width: 206px;
                background-color: #CD1719;
                border-radius: 203px;
                padding: 20px 0;
                text-align: center;
                display: flex;
                margin: 0 auto;
                align-items: center;
                justify-content: center;
                img {
                    margin-right: 8px;
                }
            }
        }
    }
    .swiper-slide {
        width: auto !important;
    }
    .swiper-slide-active {
        transition: .3s all ease-in-out;
        img {
            transform: scale(1);
            opacity: 1;
        }
        .slider-uk__item-text {
            opacity: 1;
        }
    }
    &-swiper {
        max-width: 1550px;
        margin: 0 auto;
    }
    &__nav {
        position: absolute;
        display: flex;
        justify-content: space-between;
        max-width: 750px;
        width: 100%;
        margin-bottom: 120px;
        &-left, &-right {
            width: 50px;
            height: 50px;
            background-color: #898989;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            z-index: 2;
            opacity: 1;
            cursor: pointer;
            user-select: none;
        }
        &-left {
            img {
                transform: rotate(180deg);
            }
        }
        &-right {
            img {
                margin-left: 2px;
            }
        }
    }
}