.popular-courses-list {
    margin-bottom: 32px;
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        @media(max-width: 400px) {
            flex-wrap: wrap;
            justify-content: center;
        }
        &-image {
            width: 103px;
            height: 103px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            flex: 0 0 103px;
            max-width: 103px;
            margin-right: 20px;
            @media(max-width: 400px) {
                margin-right: 0px;
                margin-bottom: 20px;
            }
            img {
                border-radius: 50%;
            }
        }
        &-text {
            flex: 0 0 calc(100% - 123px);
            max-width: calc(100% - 123px);
            @media(max-width: 400px) {
                max-width: 100%;
                flex: 0 0 100%;
            }
            &-title {
                font-size: 18px;
                line-height: 26px;
                font-weight: 700;
                margin-bottom: 16px;
            }
            &-description {
                font-size: 15px;
                line-height: 22px;
            }
        }
    }
    &__line {
        width: 100%;
        height: 1px;
        background-color: rgba(112,112,112,.16);
        margin-bottom: 31px;
    }
    &__col-12 {
        display: none;
        @media(min-width: 1024px) {
            display: block;
        }
    }
}