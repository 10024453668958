.container.w {
    max-width: 1200px;
}

.row--370 {
    .p-col {
        padding: 0 15px;
    }
    & > .col-left {
        width: 100%;
        @media(min-width: $lg) {
            max-width: calc(100% - 370px);
        }
        &.p-col {
            padding: 0 15px;
            @media(min-width: $lg) {
                max-width: calc(100% - 400px);
            }
        }
    }
    & > .col-right {
        width: 100%;
        @media(min-width: $lg) {
            max-width: 370px;
        }
        &.p-col {
            padding: 0 15px;
            @media(min-width: $lg) {
                max-width: 400px;
            }
        }
    }
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        max-width: 20%;
        flex: 0 0 20%;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        max-width: 20%;
        flex: 0 0 20%;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        max-width: 20%;
        flex: 0 0 20%;
    }
}