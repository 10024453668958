.slider-home {
    background-color: #fff;
    margin-bottom: 30px;
    padding-bottom: 18px;
    box-shadow: 0 10px 50px rgba(0,0,0,.16);
    &:hover {
        .swiper-slide-active {
            .slider-home__bottom-title {
                color: #CD1719;
            }
        }
        .slider-home__top {
            .swiper-slide-active {
                img {
                    transform: scale(1.4);
                }
            }
        }
    }
    &__top {
        border-bottom: 1px solid #DCDCDC;
        margin-bottom: 20px;
        .swiper-slide {
            a {
                overflow: hidden;
                img {
                    transition: .5s all ease-in-out;
                }
            }
            img {
                display: block;
            }
        }
        .swiper-pagination {
            position: absolute;
            bottom: 14px;
            left: 50%;
            transform: translate(-50%,0);
            z-index: 2;
            background-color: rgba(31,31,31,.3);
            padding: 0 6px;
            border-radius: 71px;
            height: 21px;
            .swiper-pagination-bullet {
                width: 9px;
                height: 9px;
                display: inline-block;
                margin-right: 3px;
                background-color: #1F1F1F;
                border-radius: 50%;
                &-active {
                    background-color: #fff;
                }
            }
        }
        .swiper-navigation {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            display: flex;
            &__left {
                margin-right: 1px;
                img {
                    transform: rotate(180deg);
                }
            }
            &__left, &__right {
                padding: 16px 20px;
                background-color: rgba(31,31,31,.3);
                cursor: pointer;
            }
        }
    }
    &__bottom {
        a {
            display: block;
            &.slider-home__bottom-description {
                @media(max-width: 500px) {
                    display: none;
                }
            }
        }
        &-title {
            font-size: 23px;
            line-height: 30px;
            font-weight: 700;
            letter-spacing: -0.025em;
            text-align: center;
            max-width: 500px;
            margin: 0 auto 10px auto;
            transition: .5s all ease-in-out;
            padding: 0 15px;
        }
        &-description {
            padding: 0 15px;
            max-width: 664px;
            margin: 0 auto 16px auto;
            text-align: center;
            font-size: 15px;
            line-height: 24px;
            @media(max-width: 500px) {
                display: none;
            }
        }
        &-info {
            display: flex;
            align-items: center;
            justify-content: center;
            &-type {
                font-size: 12px;
                line-height: 15px;
                font-weight: 600;
                margin-right: 15px;
                text-transform: uppercase;
            }
            &-category {
                background-color: #E8EBEE;
                padding: 4px 5px;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.020em;
                font-weight: 600;
                text-transform: uppercase;
                margin-right: 2px;
                display: inline-block;
            }
        }
    }
}