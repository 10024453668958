.tags {
    &__item {
        background-color: #fff;
        display: block;
        height: 100%;
        text-align: center;
        padding-bottom: 11px;
        &-image {
            margin-bottom: 10px;
            display: block;
            img {
                display: block;
            }
        }
        &-category {
            font-size: 11px;
            line-height: 13px;
            font-weight: 600;
            letter-spacing: 0.020em;
            text-transform: uppercase;
            padding: 4px 10px;
            background: #E8EBEE;
            display: inline-block;
            margin-bottom: 18px;
        }
        &-title {
            padding: 0 20px;
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -0.025em;
            margin-bottom: 24px;
            font-weight: 700;
            display: block;
        }
        &-info {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            &-date {
                margin-right: 27px;
                display: flex;
                align-items: center;
                img {
                    margin-right: 10px;
                }
            }
            &-hits {
                img {
                    margin-right: 10px;
                }
            }
        }
    }
}