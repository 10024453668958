.scroll-articles {
    background-color: #ffffff;
    padding: 15px 40px 15px 20px;
    max-width: 370px;
    margin: 0 auto 30px;
    .simplebar-track {
        right: -20px;
    }
    .simplebar-content {
        margin-bottom: 0;
    }
    &__title {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.05em;
        padding-bottom: 15px;
        border-bottom: 1px solid #F3F3F3;
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 3px;
            width: 115px;
            background-color: #CD1719;
        }
        img {
            max-width: 100%;
            height: auto;
            display: block;
        }
    }
    &__scroll {
        max-height: 500px;
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        border-bottom: 1px solid #F3F3F3;
        margin-bottom: 11px;
        &:last-child {
            border-bottom: none;
        }
        &-image {
            img {
                margin: 0;
                padding: 0;
                display: block;
                max-width: 100%;
                height: auto;
            }
        }
        &-text {
            max-width: calc(100% - 106px);
            padding-left: 20px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
        }
        &-title {
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
        }
        &-categories {
            display: flex;
            flex-wrap: wrap;
        }
        &-category {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            background-color: #F3F3F3;
            padding: 4px 5px;
        }
    }
}
