.archive {
    padding-bottom: 130px;
    &__header {
        margin: 0 0 20px;
        line-height: normal;
    }
    &__description {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        margin: 0 0 50px;
    }
    &__year {
        &-title {
            font-size: 23px;
            font-weight: 700;
            margin-bottom: 15px;
        }
        &-item {
            padding: 12px 15px;
            background-color: $color-white;
            margin-bottom: 1px;
            font-size: 17px;
            font-weight: 500;
            cursor: pointer;
        }
    }
    &__col {
        margin-bottom: 30px;
    }
    &__download {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        backdrop-filter: blur(30px) brightness(20%);
        z-index: 999;
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s ease-in-out;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        overflow: auto;
        @media(min-height: 768px) {
            justify-content: center;
        }
        &.open {
            opacity: 1;
            pointer-events: all;
        }
        &-wrap {
            background-image: url("/front/images/Elportal/archive-bg.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 50px 15px 60px;
            width: 100%;
            max-width: 770px;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                opacity: 0.96;
                background:-moz-linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
                background:-webkit-linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
                background:-o-linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CD1719', endColorstr='#1F1F1F', GradientType=1 );
                background:-ms-linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
                background:linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
            }
        }
        &-image {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto 20px;
            position: relative;
            z-index: 3;
        }
        &-text {
            color: $color-white;
            font-size: 16px;
            font-weight: 400;
            max-width: 175px;
            margin: 0 auto 20px;
            text-align: center;
            position: relative;
            z-index: 3;
        }
        &-btn {
            position: relative;
            z-index: 3;
            text-transform: uppercase;
            margin: 0 auto;
            max-width: 206px;
            min-height: 58px;
            padding: 5px;
            background-color: $color-white;
            color: #CD1719;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.05em;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            box-shadow: 0 12px 64px rgba(0,0,0,0.56);
            justify-content: center;
            border-radius: 142px;
        }
        &-close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px;
            opacity: 0.5;
            cursor: pointer;
            z-index: 3;
            &:after, &:before {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                transform: rotate(45deg);
                background-color: $color-white;
                margin: auto;
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
