.kityavt-top100 {
    display: block;
    margin: 0 auto 70px;

    picture, img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
}