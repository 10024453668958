.our-partners {
    margin-bottom: 80px;
    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(134px, 1fr));
        gap: 14px;         
    }
    &__item { 
        border: 1px solid #E9E9E9;
        background-color: #ffffff;
        padding: 1rem;
        height: 134px;
        &-logo {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
}