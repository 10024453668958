// Public sans fonts - START

@font-face {
    font-family: 'Public Sans';
    src: url('https://cdn.avt.pl/fonts/PublicSans-Light.eot');
    src: url('https://cdn.avt.pl/fonts/PublicSans-Light.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/PublicSans-Light.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/PublicSans-Light.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/PublicSans-Light.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/PublicSans-Light.svg#PublicSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Public Sans';
    src: url('https://cdn.avt.pl/fonts/PublicSans-Regular.eot');
    src: url('https://cdn.avt.pl/fonts/PublicSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/PublicSans-Regular.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/PublicSans-Regular.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/PublicSans-Regular.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/PublicSans-Regular.svg#PublicSans-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Public Sans';
    src: url('https://cdn.avt.pl/fonts/PublicSans-Medium.eot');
    src: url('https://cdn.avt.pl/fonts/PublicSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/PublicSans-Medium.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/PublicSans-Medium.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/PublicSans-Medium.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/PublicSans-Medium.svg#PublicSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Public Sans';
    src: url('https://cdn.avt.pl/fonts/PublicSans-SemiBold.eot');
    src: url('https://cdn.avt.pl/fonts/PublicSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/PublicSans-SemiBold.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/PublicSans-SemiBold.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/PublicSans-SemiBold.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/PublicSans-SemiBold.svg#PublicSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Public Sans';
    src: url('https://cdn.avt.pl/fonts/PublicSans-Bold.eot');
    src: url('https://cdn.avt.pl/fonts/PublicSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/PublicSans-Bold.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/PublicSans-Bold.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/PublicSans-Bold.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/PublicSans-Bold.svg#PublicSans-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

// Public sans fonts - END
